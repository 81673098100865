import React from 'react'
import './ingredients.scss'

const Ingredients = ({ ingredients }) =>
  ingredients &&
  ingredients.length && (
    <table className="recipe__ingredients">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Measurement</th>
          <th>Ingredient</th>
          <th>Alternative</th>
        </tr>
      </thead>
      <tbody>
        {ingredients.map((ingredient, i) =>
          ingredient.section_title ? (
            <tr key={`ingredient-${i}`}>
              <th colspan="4">{ingredient.section_title}</th>
            </tr>
          ) : (
            <tr key={`ingredient-${i}`}>
              <td>{ingredient.amount}</td>
              <td>{ingredient.measurement}</td>
              <td>{ingredient.ingredient}</td>
              <td>{ingredient.alternative}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  )

export default Ingredients
