import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Navbar from '../Navbar'
import Footer from '../Footer'
import './base.scss'

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={({ site, allMarkdownRemark: meta }) => (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{site.siteMetadata.title}</title>
          <meta name="description" content={site.siteMetadata.description} />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href="/img/safari-pinned-tab.svg"
            color="#ff4400"
          />
          <meta name="theme-color" content="#ffd700" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta property="og:image" content="/img/og-image.jpg" />
        </Helmet>
        <Navbar />
        <main className="main-content">{children}</main>
        <Footer />
      </Fragment>
    )}
  />
)

export default TemplateWrapper
