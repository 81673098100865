import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MetaNav from './MetaNav'
import './footer.scss'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allMarkdownRemark(limit: 2000) {
          tags: group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
          cuisines: group(field: frontmatter___cuisine) {
            fieldValue
            totalCount
          }
          difficulties: group(field: frontmatter___difficulty) {
            fieldValue
            totalCount
          }
          meals: group(field: frontmatter___meal) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={({ allMarkdownRemark: meta }) => (
      <footer className="main-footer">
        <div className="main-footer__inner layout-wrapper">
          <nav className="main-footer__nav">
            <MetaNav title="Meals" baseUrl="/meal/" items={meta.meals} />
            <MetaNav
              title="Cuisines"
              baseUrl="/cuisine/"
              items={meta.cuisines}
            />
            <MetaNav title="Top Tags" baseUrl="/tag/" items={meta.tags} />

            <div className="main-footer__nav__set">
              <h4 className="main-footer__nav__set__title">About</h4>
              <ul>
                <li>
                  <a href="https://grant.codes">Service by grant.codes</a>
                </li>
              </ul>
            </div>
          </nav>
          <div className="main-footer__text">
            This recipe site was made by{' '}
            <a href="https://grant.codes">grant.codes</a>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
