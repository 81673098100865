import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { kebabCase } from 'lodash'
import Ingredients from './Ingredients'
import Instructions from './Instructions'
import './recipe.scss'

class Recipe extends Component {
  render() {
    const { recipe } = this.props
    return (
      <Fragment>
        <header className="recipe__header">
          {!!recipe.image && (
            <Img
              className="recipe__image"
              fluid={recipe.image.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
          <div className="layout-wrapper">
            <h1 className="recipe__title">{recipe.title}</h1>
            <dl className="recipe__meta">
              <dt>Time</dt>
              <dd>{recipe.prepTime + recipe.cookTime} minutes</dd>
              <dt>Yeild</dt>
              <dd>{recipe.yield}</dd>
              <dt>Ingredients</dt>
              <dd>
                <Ingredients ingredients={recipe.ingredients} />
              </dd>
              <dt>Equipment Needed</dt>
              <dd>
                <ul>
                  {recipe.equipmentRequired &&
                    recipe.equipmentRequired.length &&
                    recipe.equipmentRequired.map((equipment, i) => (
                      <li key={`equipment-${i}`}>{equipment}</li>
                    ))}
                </ul>
              </dd>
              {/* <dt>Nutricional Information</dt>
              <dd>
                <table className="recipe__nutricional" />
              </dd> */}
            </dl>
          </div>
        </header>

        <div className="layout-wrapper recipe__introduction">
          {recipe.introduction}
        </div>

        <Instructions instructions={recipe.steps} />

        <div className="layout-wrapper recipe__conclusion">
          {recipe.conclusion}
        </div>

        <footer className="recipe__footer">
          <div className="layout-wrapper">
            <p className="recipe__author">
              Recipe by <Link to="https://grant.codes">Grant Richmond</Link>
            </p>
            <ul className="recipe__tags">
              {recipe.tags &&
                recipe.tags.length &&
                recipe.tags.map((tag, i) => (
                  <li key={`tag-${i}`}>
                    <Link to={`/tag/${kebabCase(tag)}`}>{tag}</Link>
                  </li>
                ))}
            </ul>
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default Recipe
