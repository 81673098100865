import React from 'react'
import { Link } from 'gatsby'
import { capitalize, kebabCase } from 'lodash'

const MetaNav = ({ title, items, baseUrl, archiveUrl, archiveTitle }) =>
  items && items.length ? (
    <div className="main-footer__nav__set">
      <h4 className="main-footer__nav__set__title">{title}</h4>
      <ul>
        {items
          .sort((a, b) => b.totalCount - a.totalCount)
          .slice(0, 10)
          .map(item => (
            <li
              key={`meta-nav-item-${item.fieldValue}`}
              className="main-footer__nav__set__item"
            >
              <Link to={baseUrl + kebabCase(item.fieldValue)}>
                {capitalize(item.fieldValue)}{' '}
                <span className="main-footer__nav__set__item__count">
                  ({item.totalCount})
                </span>
              </Link>
            </li>
          ))}
        {items.length > 10 && archiveUrl && archiveTitle && (
          <li className="main-footer__nav__set__item">
            <Link to={archiveUrl}>{archiveTitle}</Link>
          </li>
        )}
      </ul>
    </div>
  ) : null

export default MetaNav
