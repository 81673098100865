import React from 'react'
import { Link } from 'gatsby'
import ThemeSwitcher from '../ThemeSwitcher'
import './nav.scss'

const Navbar = class extends React.Component {
  render() {
    return (
      <nav className="main-nav">
        <div className="layout-wrapper main-nav__inner">
          <h2 className="main-nav__title">
            <Link to="/">🍴</Link>
          </h2>
          <div className="main-nav__spacer" />
          {/* <Link to="/account" className="main-nav__item main-nav__account">
            Account
          </Link> */}
          <ThemeSwitcher />
        </div>
      </nav>
    )
  }
}

export default Navbar
