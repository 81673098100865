import React from 'react'
import './instructions.scss'

const Instructions = ({ instructions }) =>
  instructions &&
  instructions.length && (
    <ol className="recipe__instructions">
      {instructions.map((step, i) => (
        <li className="layout-wrapper" key={`step-${i}`}>
          <span className="recipe__instructions__time">
            {step.time} Minutes
          </span>
          <img
            className="recipe__instructions__image"
            src={step.image}
            alt=""
          />
          <div className="recipe__instructions__instruction">
            {step.instruction}
          </div>
        </li>
      ))}
    </ol>
  )

export default Instructions
