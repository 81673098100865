import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import './theme-switcher.scss'

class ThemeSwitcher extends Component {
  constructor(props) {
    super(props)
    const dark =
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('theme') === 'dark'
    this.state = { dark }
  }

  handleClick = e => {
    const { dark } = this.state
    this.setState({ dark: !dark })
    window.localStorage.setItem('theme', !dark ? 'dark' : 'light')
  }

  render() {
    const { dark } = this.state
    return (
      <Fragment>
        <Helmet>
          <html className={`${dark ? 'dark' : 'light'}-theme`} />
        </Helmet>
        <button
          onClick={this.handleClick}
          className="main-nav__item main-nav__theme"
          title={`Use ${dark ? 'light' : 'dark'} theme`}
        >
          {dark ? '🌒' : '🌔'}
        </button>
      </Fragment>
    )
  }
}

export default ThemeSwitcher
